
import { Component, Vue } from "vue-property-decorator";
import { langs } from "@/config/locales.config.json";
import { routes } from "@/config/pages.config.json";
import { onLogout } from "@/api/AuthAPI/user-authorized-module";
import { AxiosError } from "axios";

@Component({})
export default class HeaderComponent extends Vue {
  locales = langs;
  routes = routes;

  routeTo(route: string): void {
    if (this.$route.name == route) return;
    this.$router.push({ name: route });
  }

  logout(): void {
    onLogout()
      .then(() => {
        this.$router.push({ name: "Login" });
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  }
}
