import axios, { AxiosResponse } from "axios";
import { auth } from "@/config/host.config.json";
import store from "@/store/index";
import router from "@/router/index";
import { IAuth } from "@/@types/auth/auth";
import { getPayload } from "@/service/accessDecoder";
const axiosApiInstance = axios.create();

export function ACCESS_REQUEST(): string {
  const storeAccess = store.getters.getAccess;
  const sessionAccess = sessionStorage.getItem("access");
  const access = storeAccess ? storeAccess : sessionAccess ? sessionAccess : "";
  return access;
}
export function REFRESH_REQUEST(): { refreshtoken: string } {
  const storeRefresh = store.getters.getAccess;
  const sessionRefresh = sessionStorage.getItem("access");
  const refresh = storeRefresh
    ? storeRefresh
    : sessionRefresh
    ? sessionRefresh
    : "";
  return { refreshtoken: refresh };
}

async function refreshAccessToken(): Promise<AxiosResponse<IAuth> | undefined> {
  const url = auth.host + auth.refresh;
  let result;
  await axios
    .post(url, REFRESH_REQUEST())
    .then((res) => {
      store.dispatch("setAccessToken", res.data.access);
      store.dispatch("setRefreshToken", res.data.refreshtoken);
      const payload = getPayload(res.data.access);
      store.dispatch("setUserRoles", payload.rules);
      result = res;
    })
    .catch(async () => {
      store.dispatch("onLogout");
      router.push({ name: "Login" });
      return;
    });
  return result;
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (request) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${ACCESS_REQUEST()}`;
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      const access_token = await refreshAccessToken();
      if (!access_token) return;

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token.data.access}`;
      originalRequest._retry = true;

      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
