import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Notifications from "vue-notification";
import i18n from "./i18n";

import { getImage, getWebImage } from "./service/getImage";
import { secondServise } from "./service/time-servise";
import { curentIndex } from "./service/getCurentIndex";
import { accessPermissions } from "./service/accessDecoder";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.config.productionTip = false;

Vue.prototype.$second = secondServise;
Vue.prototype.$getImage = getImage;
Vue.prototype.$getWebImage = getWebImage;
Vue.prototype.$getLocaleIndex = curentIndex;
Vue.prototype.$accessPermissions = accessPermissions;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
