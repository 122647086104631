import { repairSpace } from "./check_url";
import host from "@/config/host.config.json";
import { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import axios from "@/api/AuthAPI/auth-request-api";

export function getWebImage(name: string, folder: string): string {
  let image = `${host.straightPictures}/pics/${folder}/${name}`;
  if (image) {
    image = repairSpace(image);
  }
  return image;
}

export function getImage(
  imgName: string,
  folder: string | null
): string | null {
  let image: string | null = null;
  try {
    if (folder) {
      image = require(`@/assets/img/${folder}/${imgName}`);
    } else {
      image = require(`@/assets/img/${imgName}`);
    }
    if (image) {
      image = repairSpace(image);
    }
  } catch (e) {
    console.log(e);
  }
  return image;
}

export async function downloadImage(
  url: string,
  imageName: string,
  path: string
): Promise<Blob> {
  const urlImage = url + "/" + imageName;
  const config: AxiosRequestConfig = {
    responseType: "blob",
    params: { path: path },
  };
  return new Promise<Blob>((res, rej) => {
    axios
      .get(urlImage, config)
      .then((image: AxiosResponse) => {
        if (image.status === 200) {
          res(image.data);
        }
      })
      .catch((error: AxiosError) => {
        rej(error);
      });
  });
}
