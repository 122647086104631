import { auth } from "@/config/host.config.json";
import { IAuth } from "@/@types/auth/auth";
import axiosApiInstance from "./auth-request-api";
import axios from "axios";

export async function onLogin(login: string, password: string): Promise<IAuth> {
  return new Promise<IAuth>((resolve, reject) => {
    const url = auth.host + auth.login;
    const data = { login, password };
    axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}

export async function onLogout(): Promise<IAuth> {
  return new Promise<IAuth>((resolve, reject) => {
    const url = auth.host + auth.logout;
    axiosApiInstance
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => reject(err));
  });
}
