import { splitString } from "./strings";
import { IAccessPayload } from "@/@types/token-servises";
import store from "../store/index";

export function getPayload(token: string): IAccessPayload {
  const payload = splitString(token, ".")[1];
  return JSON.parse(atob(payload));
}

export function accessPermissions(server: string): boolean {
  const access = sessionStorage.getItem("access");
  if (access) {
    const permissions = getPayload(access).permissions;
    if (permissions.includes(server)) return true;
  }
  return false;
}

export function getPermitions(): string[] {
  let token = sessionStorage.getItem("access");
  if (!token) token = store.getters.getAccess;
  if (!token) return [];

  const payload = getPayload(token);
  return payload.permissions;
}
