import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    meta: {
      layout: "main",
      auth: true,
      code: "0",
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/wiki-add",
    name: "AddWikiObject",
    meta: {
      layout: "main",
      auth: true,
      code: "0",
    },
    component: () => import("../views/AddWikiObject.vue"),
  },
  {
    path: "/wiki-change",
    name: "ChangeWikiObject",
    meta: {
      layout: "main",
      auth: true,
      code: "0",
    },
    props: true,
    component: () => import("../views/ChangeWikiObject.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: "empty",
      auth: false,
    },
    component: () => import("../views/LoginView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthorized: string | null | undefined = store.getters.isAuthorized;
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (to.name !== "Login" && requireAuth && !isAuthorized) {
    next("/login");
  } else {
    next();
  }
});

export default router;
