
import { Component, Vue } from "vue-property-decorator";
import Authorized from "@/components/auth/authorized.vue";
import MainLayout from "@/components/layouts/mail-layout.vue";
import EmptyLayout from "@/components/layouts/empty-layout.vue";
@Component({
  components: {
    Authorized,
    MainLayout,
    EmptyLayout,
  },
})
export default class App extends Vue {
  get layout(): string {
    return this.$route.meta
      ? (this.$route.meta.layout || "main") + "-layout"
      : "main-layout";
  }
}
