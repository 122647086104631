import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IAuth } from "@/@types/auth/auth";
import { getPayload } from "@/service/accessDecoder";
import i18n from "@/i18n";

const state: IAuth = {
  access: sessionStorage.getItem("access") || null,
  refreshtoken: sessionStorage.getItem("refreshToken") || null,
  userRoles: sessionStorage.getItem("userRoles") || null,
};

const getters: GetterTree<IAuth, IRootState> = {
  isAuthorized(state): boolean {
    const token = sessionStorage.getItem("refreshToken");
    const auth = state.refreshtoken ? state.refreshtoken : token;
    if (!state.refreshtoken && token) state.refreshtoken = token;
    return auth ? true : false;
  },
  getAccess: (state: IAuth) => state.access,
  getRefreshToken: (state: IAuth) => state.refreshtoken,
  getUserRole: (state: IAuth) => state.userRoles,
};

const mutations: MutationTree<IAuth> = {
  setAccess: (state: IAuth, token: string) => {
    state.access = token;
    sessionStorage.setItem("access", token);
  },
  setRefreshToken: (state: IAuth, token: string) => {
    state.refreshtoken = token;
    sessionStorage.setItem("refreshToken", token);
  },
  setUserRoles: (state: IAuth, role: string) => {
    state.userRoles = role;
    sessionStorage.setItem("userRoles", role);
  },
  deleteAccessToken: (state: IAuth) => {
    state.access = null;
    sessionStorage.removeItem("access");
  },
  deleteRefreshToken: (state: IAuth) => {
    state.refreshtoken = null;
    sessionStorage.removeItem("refreshToken");
  },
  deleteUserRoles: (state: IAuth) => {
    state.userRoles = null;
    sessionStorage.removeItem("userRoles");
  },
};

const actions: ActionTree<IAuth, IRootState> = {
  setAccessToken({ commit }, token: string) {
    commit("setAccess", token);
  },
  setRefreshToken({ commit }, token: string) {
    commit("setRefreshToken", token);
  },
  setUserRoles({ commit }, role: string) {
    commit("setUserRoles", role);
  },

  deleteAccessToken({ commit }) {
    commit("deleteAccessToken");
  },
  deleteRefreshToken({ commit }) {
    commit("deleteRefreshToken");
  },
  deleteUserRoles({ commit }) {
    commit("deleteUserRoles");
  },

  onLogin({ commit }, auth: IAuth) {
    commit("setAccess", auth.access);
    commit("setRefreshToken", auth.refreshtoken);
    commit("setUserRoles", auth.access ? getPayload(auth.access).rules : "");
  },

  onLogout({ commit }) {
    commit("deleteAccessToken");
    commit("deleteRefreshToken");
    commit("deleteUserRoles");
  },
};

export const auth: Module<IAuth, IRootState> = {
  state,
  mutations,
  actions,
  getters,
};
