import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { defaultLocale, fallbackLocale } from "@/config/locales.config.json";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const localLocale = localStorage.getItem("locale");
const locale = localLocale ? localLocale : defaultLocale;

export default new VueI18n({
  locale: locale || process.env.VUE_APP_I18N_LOCALE || "ru",
  fallbackLocale:
    fallbackLocale || process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
